import {colors} from "../primitives/colors";
import React from "react";
import {
    ProductRenderDisplayVariant,
} from "../../importer/customizer/utils/get-variants-for-display";
import MockedAsset from "./mocked-asset";
import {useGridContext} from "../primitives/grid/context";
import {Link} from "../../../plugins/gatsby-plugin-atollon";
import useResizeObserver from "@react-hook/resize-observer";
import {animate} from "framer-motion";


interface SwatchProps {
    breakpoints?: {
        [key: string]: { itemsAtSize: number }
    }
    variants: ProductRenderDisplayVariant[],
    colorIndex: number,
    setColorIndex: React.Dispatch<React.SetStateAction<number>>,
    onImage?: boolean
    link: string
}


const InnerSwatch = ({ variants: productColors, colorIndex, setColorIndex, onImage, breakpoint, itemsAtSize, link
}: Omit<SwatchProps, "breakpoints"> & {
    breakpoint: string,
    itemsAtSize: number
}) =>  {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const timeoutRef = React.useRef<NodeJS.Timeout>(null);

    const refs = React.useRef<(HTMLDivElement|null)[]>([])

    const hasClamping = (el: HTMLDivElement): boolean => {
        const { clientHeight, scrollHeight } = el;
        return scrollHeight > 44;
    };

    React.useEffect(() => {
        const createButtonCheck = (): void => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            (timeoutRef as React.MutableRefObject<NodeJS.Timeout>).current = setTimeout(() => {
                if (containerRef.current && hasClamping(containerRef.current)) {

                    let hiddenCt = 0;

                    refs.current.forEach((item) => {
                        if (item && item.offsetTop > 10) {
                            hiddenCt++;
                        }
                    })

                    const el = containerRef.current.querySelector<HTMLAnchorElement>(".counter")

                    if (el) {
                        if (hiddenCt > 0) {
                            el.innerHTML = `+ ${hiddenCt} more`
                            el.style.opacity = "1"
                        } else {
                            el.style.opacity = "0"
                        }
                    }

                }
            }, 20);
        };
        // Run initially
        createButtonCheck();
        window.addEventListener('resize', createButtonCheck);
        return () => {
            window.removeEventListener('resize', createButtonCheck);
        };
    }, []);



    return (
       <div css={{
           position: 'relative',
       }}>
           <div ref={containerRef} css={{
               width: '100%',
               top: '0',
               height: 'fit-content',
               position: 'relative',
           }}>
               <div css={{
                   display: 'flex',
                   columnGap: '3px',
                   rowGap: '6px',
                   padding: '6px',
                   backgroundColor: colors.shadesWhite,
                   boxShadow: onImage ? '2px 4px 9px #0002' : undefined,
                   borderRadius: '500px',
                   width: 'fit-content',
                   zIndex: 1,
                   position: 'relative',
                   flexFlow: 'wrap',
                   maxHeight: '44px',
                   overflow: 'hidden',
               }}>
                   {productColors.map((color, idx) => {

                       return (
                           <React.Fragment key={color.colorTitle}>
                               <div role="button" css={{
                                   width: '32px',
                                   height: '32px',
                                   borderRadius: '500px',
                                   position: 'relative',
                               }} ref={(el) => {
                                   refs.current[idx] = el
                               }} onClick={(e) => {
                                   e.preventDefault();
                                   e.stopPropagation();
                                   setColorIndex(idx)
                               }} style={{
                                   border: idx === colorIndex ?  `1px solid ${colors.primaryOrange}` : `1px solid ${colors.neutral4}`,
                               }}>
                                   <MockedAsset css={{
                                       border: `1px solid ${colors.shadesWhite}`,
                                       padding: '1px',
                                       width: '30px',
                                       height: '30px',
                                       transition: 'border 0.2s',
                                       borderRadius: '500px',
                                       display: 'block',
                                       overflow: 'hidden',
                                       top: '50%',
                                       left: '50%',
                                       position: 'absolute',
                                       transform: 'translate(-50%, -50%)'
                                   }} asset={color.image} width={36} />
                               </div>
                           </React.Fragment>
                       )

                   })}
               </div>
               <Link to={link} css={{
                   marginTop: '3px',
                   fontSize: '12px',
                   textDecoration: 'underline',
                   paddingLeft: '4px',
                   opacity: 0,
                   transition: '0.3s',
               }} className="counter">
                   + 0 more
               </Link>
           </div>
       </div>
    )
}

const Swatch = ({ breakpoints = {
    dt: { itemsAtSize: 8 },
    tb: { itemsAtSize: 12 },
    mb: { itemsAtSize: 14 }
}, ...props }: SwatchProps) => {
    const { sortedBreakpoints } = useGridContext()

    const obj = sortedBreakpoints.map((x, idx) => {
        if (breakpoints[x[0]]) {
            const maybeNextBreakpoint = sortedBreakpoints[idx + 1]
            const styles = {
                display: 'none',
                [x[1].query]: {
                    display: 'block'
                }
            }

            if (maybeNextBreakpoint) {
                styles[maybeNextBreakpoint[1].query] = {
                    display: 'none'
                }
            }
            return {
                itemsAtSize: breakpoints[x[0]].itemsAtSize,
                styles,
                breakpointName: x[0]
            }
        }
    }).filter(x => !!x)

    return obj.map((v) => {
        if (v) {
            return (
                <span css={v.styles}>
                    <InnerSwatch {...props} breakpoint={v.breakpointName} itemsAtSize={v.itemsAtSize} />
                </span>
            )
        }
        return null
    })
}

export default Swatch
